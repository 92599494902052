import React, { ReactNode } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { headingFont } from '@bladebinge/web-service-common/src/constants/fonts';

export const CmsSubheading = ({
    children,
    className,
    sx = {},
    textAlign = 'inherit',
    textColor,
    useHeadingBackdrop = false
}: {
    readonly children: ReactNode;
    readonly className?: string;
    readonly sx?: { [jsonKey: string]: unknown };
    readonly textAlign?: 'left' | 'right' | 'center' | 'justify' | 'initial' | 'inherit';
    readonly textColor?: string;
    readonly useHeadingBackdrop?: boolean;
}) => {
    let justifyValue;

    switch (textAlign) {
        case 'left': {
            justifyValue = 'flex-start';
            break;
        }

        case 'right': {
            justifyValue = 'flex-end';
            break;
        }

        default: {
            justifyValue = 'center';
            break;
        }
    }

    return (
        <Grid container justifyContent={justifyValue} {...(sx ? { sx } : {})}>
            <Grid>
                <Typography
                    className={className}
                    variant="body1"
                    sx={{
                        ...(textColor ? { color: textColor } : {}),
                        fontFamily: headingFont.style.fontFamily,
                        fontWeight: 'bold',
                        fontSize: {
                            xs: '1.20rem',
                            sm: '1.70rem',
                            md: '1.80rem',
                            lg: '1.90rem'
                        },
                        textAlign,
                        ...(useHeadingBackdrop
                            ? {
                                  display: 'inline-block',
                                  backgroundColor: 'rgba(0, 0, 0, .5)',
                                  borderRadius: '4px',
                                  px: {
                                      xs: 0.25,
                                      sm: 1
                                  },
                                  pt: 0
                              }
                            : {}),
                        ...(sx ? sx : {})
                    }}
                >
                    {children}
                </Typography>
            </Grid>
        </Grid>
    );
};
