import React from 'react';
import { default as NextImage } from 'next/image';
import Link from 'next/link';
import styled from '@emotion/styled';
import { Carousel } from 'react-responsive-carousel';
import { AnimationHandlerResponse } from 'react-responsive-carousel/lib/ts/components/Carousel/types';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

interface CarouselImage {
    alt: string;
    src: string;
    link?: {
        text: string;
        url: string;
        useBlankTarget?: boolean;
    };
    imgSx?: { [jsonKey: string]: unknown };
}

const StyledAnchor = styled(Link)(({ theme }) => ({
    position: 'relative',
    display: 'block',
    width: '100%',
    height: '100%',
    textDecoration: 'none',
    color: 'inherit',
    borderRadius: '8px'
}));

// designed for images that are 1400px x 338px
export const HeroCarousel = ({ carouselImages }: { readonly carouselImages: CarouselImage[] }) => (
    <Grid
        sx={{
            position: 'relative',
            m: 0.5,
            mt: 1,
            borderRadius: '5px',
            overflowY: 'hidden'
        }}
        size={12}
    >
        <Carousel
            axis="horizontal"
            showThumbs={false}
            autoPlay
            infiniteLoop
            showStatus={false}
            showArrows={false}
            showIndicators={false}
            stopOnHover
            transitionTime={1800}
            emulateTouch={false}
            interval={6500}
            swipeable={false}
            animationHandler={(props, state): AnimationHandlerResponse => {
                const transitionTime = props.transitionTime + 'ms';
                const transitionTimingFunction = 'ease-in-out';

                const slideStyleBase: React.CSSProperties = {
                    position: 'absolute',
                    display: 'block',
                    minHeight: '100%',
                    top: 0,
                    left: 0,
                    transitionTimingFunction,
                    msTransitionTimingFunction: transitionTimingFunction,
                    MozTransitionTimingFunction: transitionTimingFunction,
                    WebkitTransitionTimingFunction: transitionTimingFunction,
                    OTransitionTimingFunction: transitionTimingFunction,
                    WebkitTransitionDuration: transitionTime,
                    MozTransitionDuration: transitionTime,
                    OTransitionDuration: transitionTime,
                    transitionDuration: transitionTime,
                    msTransitionDuration: transitionTime,
                    transitionProperty: 'opacity, top'
                };

                return {
                    slideStyle: {
                        ...slideStyleBase,
                        opacity: 0,
                        zIndex: 0,
                        top: '100%'
                    },
                    selectedStyle: {
                        ...slideStyleBase,
                        position: 'relative',
                        opacity: 1,
                        zIndex: 1,
                        top: 0
                    },
                    prevStyle: {
                        ...slideStyleBase,
                        opacity: 0,
                        zIndex: 0,
                        top: '100%'
                    }
                };
            }}
        >
            {carouselImages.map((image, index) => (
                <Grid container key={`hero_${image.src}`}>
                    <Grid size={12}>
                        <Box
                            sx={{
                                position: 'relative',
                                top: 0,
                                left: 0,
                                width: '100%',
                                aspectRatio: '4.14 / 1'
                            }}
                        >
                            {image?.link ? (
                                <StyledAnchor
                                    {...(image.link?.text ? { title: image.link.text } : {})}
                                    {...(image.link?.useBlankTarget ? { target: '_blank' } : {})}
                                    href={image.link.url}
                                >
                                    <NextImage
                                        alt={image.alt}
                                        src={image.src}
                                        fill
                                        priority={index === 0}
                                        sizes="(max-width: 600px) 560px, (max-width: 900px) 790px, (max-width: 1200px) 1075px, (max-width: 1536px) 1380px"
                                        style={{ borderRadius: '8px' }}
                                    />
                                </StyledAnchor>
                            ) : (
                                <NextImage
                                    alt={image.alt}
                                    src={image.src}
                                    fill
                                    priority
                                    sizes="(max-width: 600px) 560px, (max-width: 900px) 790px, (max-width: 1200px) 1075px, (max-width: 1536px) 1380px"
                                    style={{ borderRadius: '8px' }}
                                />
                            )}
                        </Box>
                    </Grid>
                </Grid>
            ))}
        </Carousel>
    </Grid>
);
