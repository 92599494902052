import React from 'react';
import { Theme as MaterialUITheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { CmsPlacement } from './CmsPlacement';
import { BuySellRateRepeat } from './cms-atoms/BuySellRateRepeat';
import { WelcomeHeroCarousel } from './WelcomeHeroCarousel';
import { StackedHelpLinks } from './StackedHelpLinks';
import { BrowsingJumpTiles } from '.';

// Re-declare the emotion theme to have the properties of the MaterialUiTheme
declare module '@emotion/react' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    export interface Theme extends MaterialUITheme {}
}

export const WelcomePage = ({ listingPagingLimit }: { readonly listingPagingLimit?: number }) => (
    <Grid container spacing={1} justifyContent="space-between">
        <WelcomeHeroCarousel />
        <BuySellRateRepeat />
        <Grid container justifyContent="space-between">
            <Grid
                container
                size={{
                    xs: 12,
                    sm: 12,
                    md: 8
                }}
            >
                <BrowsingJumpTiles
                    listingPagingLimit={listingPagingLimit}
                    gridWidthXs={6}
                    gridWidthSm={6}
                    gridWidthMd={6}
                />
            </Grid>
            <Grid
                className="bazilla"
                container
                alignContent="flex-start"
                size={{
                    xs: 12,
                    sm: 12,
                    md: 4
                }}
            >
                <Grid size={12}>
                    <CmsPlacement
                        gridWidthXs={12}
                        gridWidthSm={12}
                        gridWidthMd={12}
                        headingTranslationKey="cms:headlines.why_bladebinge"
                        taglines={[
                            {
                                taglineTranslationHeadingKey: 'cms:taglines.anyone_can_buy_or_sell_tagline_heading',
                                taglineTranslationKey: 'cms:taglines.anyone_can_buy_or_sell_tagline',
                                headingTextColor: 'primary.main'
                            },
                            {
                                taglineTranslationHeadingKey: 'cms:taglines.secure_payments_tagline_heading',
                                taglineTranslationKey: 'cms:taglines.secure_payments_tagline',
                                headingTextColor: 'primary.main'
                            },
                            {
                                taglineTranslationHeadingKey: 'cms:taglines.trustworthy_tagline_heading',
                                taglineTranslationKey: 'cms:taglines.trustworthy_tagline',
                                headingTextColor: 'primary.main'
                            },
                            {
                                taglineTranslationHeadingKey: 'cms:taglines.real_humans_tagline_heading',
                                taglineTranslationKey: 'cms:taglines.real_humans_tagline',
                                headingTextColor: 'primary.main'
                            }
                        ]}
                        useHeadingBackdrop={false}
                        sx={{ fontWeight: 'bold' }}
                    />
                </Grid>
                <CmsPlacement
                    gridWidthXs={12}
                    gridWidthSm={12}
                    gridWidthMd={12}
                    subheadingTranslationKey="cms:help.new_to_bladebinge"
                    useHeadingBackdrop={false}
                    sx={{
                        minHeight: '380px',
                        backgroundPosition: 'top center',
                        minWidth: {
                            xs: 'auto',
                            sm: 'auto',
                            md: '340px',
                            lg: 'auto',
                            xl: 'auto'
                        }
                    }}
                >
                    <StackedHelpLinks />
                </CmsPlacement>
            </Grid>
        </Grid>
    </Grid>
);
