import React from 'react';
import { default as NextImage } from 'next/image';
import Link from 'next/link';
import styled from '@emotion/styled';
import { useTranslation } from 'next-i18next';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import { getCdnUrl } from '@bladebinge/web-service-common/src/utils/get-cdn-url';
import { CmsComponentProps } from '@bladebinge/types';
import Box from '@mui/material/Box';
import { Taglines } from './cms-atoms/Taglines';
import { StyledCmsHeading } from './cms-atoms/CmsHeading';
import { CmsSubheading } from './cms-atoms/CmsSubheading';

const StyledAnchor = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    color: 'inherit',
    textShadow: '1px 1px 1px #36454F'
}));

export const CmsPlacement = ({
    aspectRatio,
    backgroundImageUrl,
    children,
    gridWidthXs = 3, // default to 1/4
    gridWidthSm = 3, // default to 1/4
    gridWidthMd = 3, // default to 1/4
    headingComponent = null,
    headingTranslationKey,
    linkTitle,
    primaryLinkUrl,
    raised = false,
    SeoHeadingComponent = 'h3',
    seoHeadingTranslationKey,
    subheadingComponent = null,
    subheadingTranslationKey,
    sizes = '',
    taglines = [],
    textAlign = 'center',
    textColor,
    useBlankLinkTarget = false,
    useHeadingBackdrop = true,
    headingSx = {},
    subheadingSx = {},
    sx = {}
}: CmsComponentProps) => {
    const { t } = useTranslation();
    const imageSizes = sizes
        ? sizes
        : '(max-width: 600px) 180px, (max-width: 900px) 385px, (max-width: 1200px) 345px, (max-width: 1536px) 445px';

    const innerContent = (
        <Card
            raised={raised}
            sx={{
                position: 'relative',
                height: '100%',
                py: 1,
                ...(primaryLinkUrl
                    ? {
                          '& .cms-backdrop': {
                              opacity: 0.9
                          },
                          '&:hover .cms-backdrop': {
                              opacity: 1
                          }
                      }
                    : {}),
                ...(sx ? sx : {})
            }}
        >
            <>
                {backgroundImageUrl && (
                    <Box
                        className="background-content"
                        sx={{ position: 'absolute', top: 0, left: 0, zIndex: 0, width: '100%', height: '100%' }}
                    >
                        {SeoHeadingComponent && seoHeadingTranslationKey ? (
                            <SeoHeadingComponent
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    padding: 0,
                                    margin: 0
                                }}
                            >
                                <NextImage
                                    className="cms-backdrop"
                                    alt={t(seoHeadingTranslationKey)}
                                    priority
                                    sizes={imageSizes}
                                    src={getCdnUrl(backgroundImageUrl)}
                                    fill
                                />
                            </SeoHeadingComponent>
                        ) : (
                            <NextImage
                                alt=""
                                className="cms-backdrop"
                                fill
                                priority
                                sizes={imageSizes}
                                src={getCdnUrl(backgroundImageUrl)}
                            />
                        )}
                    </Box>
                )}
                <Box
                    className="foreground-content"
                    sx={{ position: 'relative', zIndex: 2, backgroundColor: 'transparent' }}
                >
                    {headingComponent}
                    {headingTranslationKey && !headingComponent && (
                        <StyledCmsHeading
                            textColor={textColor}
                            textAlign={textAlign}
                            useHeadingBackdrop={useHeadingBackdrop}
                            sx={headingSx}
                        >
                            {t(headingTranslationKey)}
                        </StyledCmsHeading>
                    )}
                    {subheadingComponent}
                    {subheadingTranslationKey && !subheadingComponent && (
                        <CmsSubheading
                            textColor={textColor}
                            textAlign={textAlign}
                            useHeadingBackdrop={useHeadingBackdrop}
                            sx={subheadingSx}
                        >
                            {t(subheadingTranslationKey)}
                        </CmsSubheading>
                    )}
                    {taglines && <Taglines taglines={taglines} />}
                    {children}
                </Box>
            </>
        </Card>
    );

    return (
        <Grid
            sx={{
                position: 'relative',
                p: 0.5,
                ...(aspectRatio ? { aspectRatio } : {}),
                overflow: 'hidden'
            }}
            size={{
                xs: gridWidthXs,
                sm: gridWidthSm,
                md: gridWidthMd
            }}
        >
            {primaryLinkUrl ? (
                <StyledAnchor
                    {...(linkTitle ? { title: linkTitle } : {})}
                    href={primaryLinkUrl}
                    {...(useBlankLinkTarget ? { target: '_blank' } : {})}
                >
                    {innerContent}
                </StyledAnchor>
            ) : (
                innerContent
            )}
        </Grid>
    );
};
