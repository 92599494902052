import React from 'react';
import Grid from '@mui/material/Grid';
import { WelcomeHeroCarousel } from '@bladebinge/cms/src/WelcomeHeroCarousel';
import { BrowsingJumpTiles } from '@bladebinge/cms/src/BrowsingJumpTiles';
import { StackedHelpLinks } from '@bladebinge/cms/src/StackedHelpLinks';
import { BuySellRateRepeat } from '@bladebinge/cms';
import { useUserHomePageFavoriteListings } from '../../hooks/react-query/logged-in-user-hooks/use-user-home-page-favorite-listings';
import { useUserViewedListings } from '../../hooks/react-query/logged-in-user-hooks/use-user-viewed-listings';
import { usePriceDroppedListings } from '../../hooks/react-query/use-price-dropped-listings';
import { useRecentlySoldListings } from '../../hooks/react-query/use-recently-sold-listings';
import { HomePageListingGroup } from './HomePageListingGroup';

export const LoggedInLandingPage = () => (
    <Grid container justifyContent="space-between" spacing={2} sx={{ my: 1 }}>
        <WelcomeHeroCarousel />
        <Grid container justifyContent="space-evenly" sx={{ my: 1 }} size={12}>
            <BrowsingJumpTiles
                borderWidth="2px"
                gridWidthXs={4}
                gridWidthSm={4}
                gridWidthMd={2}
                subheadingSx={{
                    fontSize: {
                        xs: '1.1rem',
                        sm: '1.1rem',
                        md: '1.25rem'
                    }
                }}
            />
        </Grid>
        <Grid
            container
            justifyContent="flex-start"
            alignItems="flex-start"
            size={{
                xs: 12,
                sm: 12,
                md: 6
            }}
        >
            <HomePageListingGroup
                key="price_drops_home"
                dataHook={usePriceDroppedListings}
                groupId="price_drops_home"
                noResultsTKey="common:logged_in_home.price_drop_no_results_text"
                titleTKey="common:logged_in_home.price_drops"
            />
        </Grid>
        <Grid
            container
            justifyContent="flex-start"
            alignItems="flex-start"
            size={{
                xs: 12,
                sm: 12,
                md: 6
            }}
        >
            <HomePageListingGroup
                key="viewed_listings_home"
                dataHook={useUserViewedListings}
                groupId="viewed_listings_home"
                noResultsTKey="common:logged_in_home.recently_viewed_no_results_text"
                titleTKey="common:logged_in_home.recently_viewed"
            />
        </Grid>
        <Grid
            container
            justifyContent="flex-start"
            alignItems="flex-start"
            size={{
                xs: 12,
                sm: 12,
                md: 6
            }}
        >
            <HomePageListingGroup
                key="favorites_home"
                dataHook={useUserHomePageFavoriteListings}
                groupId="favorites_home"
                noResultsTKey="common:logged_in_home.favorites_no_results_text"
                titleTKey="common:logged_in_home.favorites"
            />
        </Grid>
        <Grid
            container
            justifyContent="flex-start"
            alignItems="flex-start"
            size={{
                xs: 12,
                sm: 12,
                md: 6
            }}
        >
            <HomePageListingGroup
                key="recently_sold_home"
                dataHook={useRecentlySoldListings}
                groupId="recently_sold_home"
                noResultsTKey="common:logged_in_home.recently_sold_no_results_text"
                titleTKey="common:logged_in_home.recently_sold"
            />
        </Grid>
        <Grid sx={{ textAlign: 'center' }} size={12}>
            <BuySellRateRepeat />
            <StackedHelpLinks />
        </Grid>
    </Grid>
);
